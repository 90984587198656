import React from 'react'
import { Switch } from '@nextui-org/react'
import { useTheme } from 'next-themes'
import { SunIcon, MoonIcon } from '../Icons/Icons.jsx'

export const ThemeSwitcher = () => {
  const { theme, setTheme } = useTheme()

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light')
  }

  return (
    <>
      <Switch
        defaultChecked={theme === 'dark'}
        size="sm"
        color="default"
        onChange={toggleTheme}
        thumbIcon={({ isSelected }) =>
          isSelected ? (
            <SunIcon className="text-primary" fill="currentColor" size={30} />
          ) : (
            <MoonIcon className="text-primary" fill="currentColor" size={30} />
          )
        }
      ></Switch>
    </>
  )
}
