import React from 'react'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { Button } from '@nextui-org/react'
import { useTranslation } from 'react-i18next'
import { FaPlay } from "react-icons/fa";

import ButtonLoader from '../ButtonLoader/ButtonLoader'
import DelayReport from '../DelayReport/DelayReport'

const UnDelayReport = ({ reportId }) => {
  const { t } = useTranslation('ReportPages')

  const [messageError, setMessageError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [resumed, setResumed] = useState(false)

  async function HandleUnDelayReport() {
    setIsLoading(true)
    try {
      const resumeReport = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}reports/resumeReport/${reportId}`,
        {},
        {
          headers: {
            token: Cookies.get('loginToken'),
          },
        },
      )
      if (resumeReport.data.message === 'success') {
        setResumed(true)
        setIsLoading(false)
      }
    } catch (err) {
      setIsLoading(false)
      setMessageError(err.response.data.err)
    }
  }

  return (
    <>
      {resumed ? (
        <DelayReport _id={reportId} />
      ) : (
        <Button
          type="submit"
          className={isLoading ? 'bg-transparent' : 'bg-delay-color text-white'}
          size="sm"
          onClick={HandleUnDelayReport}
        >
          {isLoading ? <ButtonLoader /> : <>
            <FaPlay />
            {t('delayed')}
          </>}
        </Button>
      )}
    </>
  )
}

export default UnDelayReport
