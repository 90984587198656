import React from 'react'
import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Cookies from 'js-cookie'
import axios from 'axios'
import {
  Textarea,
  Select,
  SelectItem,
  Input,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@nextui-org/react'
import Categories from '../CommonReportComponents/Categories.jsx'
import { useTranslation } from 'react-i18next'
import { FaRegEdit } from 'react-icons/fa'
import ButtonLoader from '../ButtonLoader/ButtonLoader.jsx'

const EditReport = (props) => {
  const categoriesArray = Categories()
  const { t } = useTranslation('ReportActions')
  const { i18n } = useTranslation()

  const reportData = props.reportData
  const [messageError, setMessageError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { isOpen, onOpen, onOpenChange } = useDisclosure()

  const validationSchema = Yup.object({
    reporterName: Yup.string().max(50, 'يجب الا يزيد الاسم عن 50 حرف'),
    placeName: Yup.string(),
    description: Yup.string().max(240, 'يجب الا يزيد الوصف عن 240 حرف'),
    category: Yup.string(),
  })

  const formik = useFormik({
    initialValues: {
      reporterName: reportData.reporterName || '',
      placeName: reportData.placeName || '',
      category: reportData.category || '',
      description: reportData.description || '',
    },
    validationSchema: validationSchema,
    onSubmit: updateReport,
  })

  async function updateReport(values) {
    setIsLoading(true)

    const newData = { ...values }

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}reports/${reportData._id}`,
        newData,
        {
          headers: {
            token: Cookies.get('loginToken'),
          },
        },
      )
      if (response.data.message === 'success') {
        onOpenChange()
        setMessageError(null)
        setIsLoading(false)
      }
    } catch (err) {
      setMessageError(err.response.data.err)
      setIsLoading(false)
    }
  }

  async function deleteReport() {
    setIsLoading(true)
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}reports/${reportData._id}`,
        {
          headers: {
            token: Cookies.get('loginToken'),
          },
        },
      )

      if (response.message === 'success') {
        isOpen(false)
      }
    } catch (err) {
      setMessageError(err.response.data.err)
    }

    setIsLoading(false)
  }

  return (
    <>
      <Button onPress={onOpen} size="sm">
        <FaRegEdit />
        {t('edit')}
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="3xl"
         placement='center'
        dir={i18n.dir()}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {t('editReport')}
              </ModalHeader>
              <form onSubmit={formik.handleSubmit}>
                <ModalBody>
                  <div className="flex w-full flex-wrap ">
                    <Input
                      className="mb-3"
                      variant="faded"
                      type="text"
                      label={t('name')}
                      placeholder={reportData.reporterName}
                      name="reporterName"
                      onChange={formik.handleChange}
                    />
                    <Input
                      className="mb-3"
                      variant="faded"
                      type="text"
                      label={t('placeName')}
                      placeholder={reportData.placeName}
                      name="placeName"
                      onChange={formik.handleChange}
                    />
                    <Select
                      variant="faded"
                      name="category"
                      label={t('category')}
                      placeholder={reportData.category}
                      onChange={formik.handleChange}
                    >
                      {categoriesArray.map((category) => (
                        <SelectItem key={category.value} value={category.value}>
                          {category.label}
                        </SelectItem>
                      ))}
                    </Select>

                    <Textarea
                    className='mt-3'
                      name="description"
                      variant="faded"
                      label={t('description')}
                      placeholder={reportData.description}
                      onChange={formik.handleChange}
                    />
                  </div>
                  {messageError ? messageError : null}
                </ModalBody>
                <ModalFooter>
                  <div className="w-full flex justify-between">
                    <Button onClick={deleteReport} color="danger"></Button>
                    <div>
                      <Button color="danger" variant="light" onPress={onClose}>
                        {t('cancel')}
                      </Button>
                      <Button
                        type="submit"
                        className={
                          isLoading ? 'bg-transparent' : 'bg-main text-white'
                        }
                      >
                        {isLoading ? <ButtonLoader /> : t('submit')}
                      </Button>
                    </div>
                  </div>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}

export default EditReport
