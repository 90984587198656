import notfound from '../../assets/404.svg'
const NotFound = () => {
  return (
    <>
      <div className="w-full h-full flex justify-center items-center">
        <img src={notfound} className="w-1/2" alt="" />
      </div>
    </>
  )
}

export default NotFound
