import './Layout.module.css'
import { Outlet } from 'react-router-dom'
import MainNav from '../MainNav/MainNav'
import Footer from '../Footer/Footer'
import { useTranslation } from 'react-i18next'

const Layout = () => {
  const { i18n } = useTranslation()

  return (
    <>
      <div className="flex flex-col h-screen " dir={i18n.dir()}>
        <MainNav></MainNav>
        <div
          className="layout flex-grow bg-gray-200 dark:bg-form-color"
          dir={i18n.dir()}
        >
          <Outlet dir={i18n.dir()} />
        </div>
        <Footer></Footer>
      </div>
    </>
  )
}

export default Layout
