import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Translation } from '../Icons/Icons'

const LanguageSelector = () => {
  const languages = [
    {
      name: 'العربية',
      code: 'ar',
    },
    {
      name: 'English',
      code: 'en',
    },
    {
      name: 'Russian',
      code: 'ru',
    },
    {
      name: 'French',
      code: 'fr',
    },
  ]

  const { i18n } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    // Retrieve the language from local storage, default to browser language if not found
    return localStorage.getItem('selectedLanguage') || i18n.language
  })

  useEffect(() => {
    // Update the selected language in local storage whenever it changes
    localStorage.setItem('selectedLanguage', selectedLanguage)
    // Change the language in i18n
    i18n.changeLanguage(selectedLanguage)
  }, [selectedLanguage, i18n])

  const handleLanguageChange = (code) => {
    setSelectedLanguage(code)
    window.location.reload() // Reloading the page is usually not necessary for a language change
  }

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button size="sm" variant="light">
          <Translation />
        </Button>
      </DropdownTrigger>
      <DropdownMenu aria-label="Language Selector">
        {languages.map((language) => (
          <DropdownItem
            key={language.code}
            onClick={() => handleLanguageChange(language.code)}
          >
            {language.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}

export default LanguageSelector
