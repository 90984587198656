import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [loginToken, setLoginToken] = useState(Cookies.get('loginToken') || null);
    const [user, setUser] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const validateTokenFormat = (token) => {
            if (!token) return false;
            const parts = token.split('.');
            return parts.length === 3;
        };

        const decodeToken = (token) => {
            try {
                return jwtDecode(token);
            } catch (error) {
                console.error('Invalid token specified:', error.message);
                return null;
            }
        };

        const TokenValidator = async () => {
            setLoading(true);
            if (loginToken && validateTokenFormat(loginToken)) {
                const decodedToken = decodeToken(loginToken);

                if (!decodedToken) {
                    Cookies.remove('loginToken');
                    setLoginToken(null);
                    setUser(null);
                    setLoading(false);
                    return;
                }

                if (decodedToken.exp * 1000 < Date.now()) {
                    console.error('Token expired');
                    Cookies.remove('loginToken');
                    setLoginToken(null);
                    setUser(null);
                    setLoading(false);
                    return;
                }

                try {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}auth/tokenValidate`, {
                        headers: {
                            token: loginToken,
                        },
                    });

                    if (response.data.message === 'success') {
                        setUser(response.data.user); 
                        setPermissions(response.data.user.permissions)
                    } else {
                        Cookies.remove('loginToken');
                        setLoginToken(null);
                        setUser(null);
                    }
                } catch (error) {
                    console.error('Error validating token:', error);
                    Cookies.remove('loginToken');
                    setLoginToken(null);
                    setUser(null);
                }
            } else {
                Cookies.remove('loginToken');
                setLoginToken(null);
                setUser(null);
            }
            setLoading(false);
        };

        TokenValidator();
    }, [loginToken]);

    const login = (token, userData) => {
        Cookies.set('loginToken', token);
        setLoginToken(token);
        setUser(userData); 
    };

    const logout = () => {
        
        Cookies.remove('loginToken', { path: '/' });
        Cookies.remove('userRole');
        Cookies.remove('userName');
        Cookies.remove('permissions');
        setLoginToken(null);
        setUser(null);
        window.location.href = "/";
    };

    const isAuthenticated = () => !!loginToken;

    const value = {
        loginToken,
        user,
        permissions,
        loading,
        isAuthenticated,
        login,
        logout,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
