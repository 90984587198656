import React from 'react';
import { useTranslation } from 'react-i18next';

const TranslatePermission = ({ permission }) => {
  const { t } = useTranslation('Permissions'); // 'permission' is the namespace
  
  // Translate the permission using the t function
  const translatedPermission = t(permission);

  return <p>{translatedPermission}</p>;
};

export default TranslatePermission;
