import React from 'react';
import { useAuth } from '../../Context/auth.context';
import NotFound from '../NotFound/NotFound';

export default function IsAuth({ children }) {
    let auth = useAuth();
    if (auth.loginToken) {
        return <>{children}</>; // Render the children if the user is authenticated
    }
    return <NotFound></NotFound>
}
