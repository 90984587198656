import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'

// Get language from local storage or default to "ar"
const storedLanguage = localStorage.getItem('selectedLanguage') || 'ar'

i18n.use(LanguageDetector).use(initReactI18next).use(Backend).init({
  fallbacklng: 'ar',
  returnObjects: true,
  lng: storedLanguage, // Set the language from local storage
})

export default i18n
