import { createContext, useEffect, useState } from 'react'
import { io } from 'socket.io-client'
import Cookies from 'js-cookie'
export let reportContext = createContext()

export default function ReportContextProvider(props) {
  const [socket, setSocket] = useState(null)
  const [token, setToken] = useState(null)
  const [newReport, setNewReport] = useState({})
  const [delayReport, setDelayReport] = useState({})
  const [finishReport, setFinishReport] = useState({})
  const [deleteReport, setDeleteReport] = useState({})
  const [updateReport, setUpdateReport] = useState({})
  const [resumeReport, setResumeReport] = useState({})
  const [newAuditLog, setNewAuditLog] = useState({})
  const [reopenReport, setReopenReport] = useState({})
  const [receiveReport, setReceiveReport] = useState({})
  const [transferredReport, setTransferredReport] = useState({})

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_SOCKET_URL)
    setSocket(newSocket)
    const cookiesToken = Cookies.get('loginToken')
    setToken(cookiesToken)

    newSocket.on('connect', () => {
      if (cookiesToken) {
        newSocket.emit('newUser', { token })
      }
    })
    return () => {
      newSocket.disconnect()
    }
  }, [token])

  useEffect(() => {
    if (socket === null) return
    socket.on('newReport', (report) => {
      setNewReport(report)
    })

    return () => {
      socket.off('newReport')
    }
  }, [socket])

  useEffect(() => {
    if (socket === null) return
    socket.on('delayReport', (report) => {
      setDelayReport(report)
    })

    return () => {
      socket.off('delayReport')
    }
  }, [socket])

  useEffect(() => {
    if (socket === null) return
    socket.on('finishReport', (report) => {
      setFinishReport(report)
    })

    return () => {
      socket.off('finishReport')
    }
  }, [socket])

  useEffect(() => {
    if (socket === null) return
    socket.on('deleteReport', (report) => {
      setDeleteReport(report)
    })

    return () => {
      socket.off('deleteReport')
    }
  }, [socket])

  useEffect(() => {
    if (socket === null) return
    socket.on('updateReport', (report) => {
      setUpdateReport(report)
    })

    return () => {
      socket.off('updateReport')
    }
  }, [socket])

  //resumeReport
  useEffect(() => {
    if (socket === null) return
    socket.on('resumeReport', (report) => {
      setResumeReport(report)
    })

    return () => {
      socket.off('resumeReport')
    }
  }, [socket])

  //newAuditLog
  useEffect(() => {
    if (socket === null) return
    socket.on('newAuditLog', (report) => {
      setNewAuditLog(report)
    })

    return () => {
      socket.off('newAuditLog')
    }
  }, [socket])

  //reopenReport
  useEffect(() => {
    if (socket === null) return
    socket.on('reopenReport', (report) => {
      setReopenReport(report)
    })

    return () => {
      socket.off('reopenReport')
    }
  }, [socket])

  //receiveReport
  useEffect(() => {
    if (socket === null) return
    socket.on('receiveReport', (report) => {
      setReceiveReport(report)
    })

    return () => {
      socket.off('receiveReport')
    }
  }, [socket])



  //transferredReport
  useEffect(() => {
    if (socket === null) return
    socket.on('transferredReport', (report) => {
      setTransferredReport(report)
    })

    return () => {
      socket.off('transferredReport')
    }
  }, [socket])

  return (
    <reportContext.Provider
      value={{
        socket,
        newReport,
        delayReport,
        finishReport,
        deleteReport,
        updateReport,
        resumeReport,
        newAuditLog,
        reopenReport,
        receiveReport,
        transferredReport
      }}
    >
      {props.children}
    </reportContext.Provider>
  )
}
