import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Button, Divider, Image } from '@nextui-org/react';
import Zoom from 'react-medium-image-zoom'; // Component for zooming images
import 'react-medium-image-zoom/dist/styles.css';

// Local imports
import Loader from '../Loader/Loader';
import UnDelayReport from '../UnDelayReport/UnDelayReport';
import DelayReport from '../DelayReport/DelayReport';
import TransferReports from '../TransferReports/TransferReports';
import FinishReport from '../FinishReport/FinishReport';
import ReciveReport from '../ReciveReport/ReciveReport';
import EditReport from '../EditReport/EditReport';
import { useAuth } from '../../Context/auth.context';
import { useTranslation } from 'react-i18next';
import CalculateFinishedTime from '../CalculateFinishedTime/CalculateFinishedTime';
import ReopenReport from '../ReopenReport/ReopenReport';
import withSocketEvents from '../../HOCs/withSocketEvents'; // Import the HOC

const ReportDetails = ({ tableReports, setTableReports }) => {
  const { t } = useTranslation("ReportDetails");
  const { reportId } = useParams();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [report, setReport] = useState(null);

  useEffect(() => {
    setLoading(true);
    const fetchReport = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}reports/getReport/${reportId}`,
          {
            headers: { token: Cookies.get('loginToken') },
          }
        );
        setReport(res.data.data);
        setLoading(false);
      } catch (err) {
        console.error(err.response.data.err);
        setError(err.response.data.err);
        setLoading(false);
      }
    };

    fetchReport();
  }, [reportId]);

  useEffect(() => {
    const currentReport = tableReports.find(r => r._id === reportId);
    if (currentReport) {
      setReport(currentReport);
    }
  }, [tableReports, reportId]);


  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div className='flex justify-center items-center h-full text-red-500'>{error}</div>;
  }

  if (!report) {
    return null; // or a fallback UI, e.g., <div>No report found</div>
  }

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <div className='w-full h-full bg-gray-200 dark:bg-form-color p-4'>
      <div className='w-full flex justify-center items-center'>
        <div className='w-1/2 max-lg:w-11/12'>
          {report.image && <Zoom><Image src={report.image?.secure_url} alt={report.name} /></Zoom>}
        </div>
      </div>
      <div className='flex justify-center flex-wrap'>
        <div className='w-1/2 max-lg:w-11/12'>
          <div>
            <h2 className='w-full my-1'><span className='font-extrabold'>{t("reporterName")}</span> {report.reporterName}</h2>
            <h2 className='w-full my-1'><span className='font-extrabold'>{t("reporter")}</span> {report.reportBy}</h2>
            {report.phoneNumber ? <h2 className='w-full my-1'><span className='font-extrabold'>{t("phoneNumber")}</span> {report.phoneNumber}</h2> : null}
            <h2 className='w-full my-1'><span className='font-extrabold'>{t("place")}</span> {report.placeName}</h2>
            <h2 className='w-full my-1'><span className='font-extrabold'>{t("category")}</span> {report.category}</h2>
            <h2 className='w-full my-1'><span className='font-extrabold'>{t("description")}</span> {report.description}</h2>
            <h2 className='w-full my-1'><span className='font-extrabold'>{t("createdAt")}</span> {formatDate(report.createdAt)}</h2>
            {report.delayedReason ? <h2 className='w-full my-1'><span className='font-extrabold'>{t("delayReason")}</span> {report.delayedReason}</h2> : null}
            {report.finishedReason ? <h2 className='w-full my-1'><span className='font-extrabold'>{t("finishReason")}</span> {report.finishedReason}</h2> : null}
            {report.reopenReason ? <h2 className='w-full my-1'><span className='font-extrabold'>{t("reopenReason")} : </span> {report.reopenReason}</h2> : null}
            {report.receivedAt ? <h2 className='w-full my-1'><span className='font-extrabold'>{t("receivedDate")}</span> {formatDate(report.receivedAt)}</h2> : null}
            {report.receivedBy ? <h2 className='w-full my-1'><span className='font-extrabold'>{t("receivedBy")}</span> {report.receivedBy.username}</h2> : null}
            {report.finishedBy ? <h2 className='w-full my-1'><span className='font-extrabold'>{t("finshedBy")}</span> {report.finishedBy}</h2> : null}
            {report.duration ? <h2 className='w-full my-1 flex'><span className='font-extrabold'>{t("duration")}</span> <CalculateFinishedTime duration={report.duration} /></h2> : null}
            {/* {report.totalDelayDuration ? <h2 className='w-full my-1 flex'><span className='font-extrabold'>{t("totalDelayDuration")}</span> <CalculateFinishedTime duration={report.totalDelayDuration} /></h2> : null} */}
          </div>
          <div className='w-full mt-6'>
            <Divider></Divider>
            <div className='w-full mt-6'>
              {report.finished ? (
                <div className="flex flex-col gap-2">
                  {user.permissions?.reopenReports === true || user.permissions?.administrator === true ?
                    <ReopenReport _id={report._id} />
                    :
                    <Button
                      className="opacity-50 disabled bg-main text-white"
                      size="sm"
                      disabled
                    >
                      {t('finished')}
                    </Button>
                  }
                </div>
              ) : (
                <div className="flex flex-col gap-2">
                  {report.finished ? (
                    <div className="flex flex-col gap-2">
                      {user.permissions?.reopenReports === true || user.permissions?.administrator === true ?
                        <ReopenReport _id={report._id} />
                        :
                        <Button
                          className="opacity-50 disabled bg-main text-white"
                          size="sm"
                          disabled
                        >
                          {t('finished')}
                        </Button>
                      }
                    </div>
                  ) : (
                    <div className="flex flex-col gap-2">

                      {report.isDelayed ? (
                        <>
                          {user.permissions?.resumeReports === true || user.permissions?.administrator === true ? <UnDelayReport reportId={report._id} /> : null}
                        </>
                      ) : <>
                        {user.permissions?.delayReports === true || user.permissions?.administrator === true ? <DelayReport _id={report._id} /> : null}

                        {/* recive and finish report start */}
                        {report.isReceived === true && report.isDelayed === false ?
                          <>
                            {user.permissions?.receiveReports === true || user.permissions?.administrator === true ? <>{user?.id === report.receivedBy?._id ? < FinishReport _id={report._id} /> : null}</> : null}
                          </>
                          :
                          <>
                            {user.permissions?.receiveReports === true || user.permissions?.administrator === true ? <ReciveReport _id={report._id} /> : null}
                          </>

                        }
                        {/* recive and finish report end */}
                      </>
                      }
                      {report.isReceived ? null : <>{user.permissions?.transferReports === true || user.permissions?.administrator === true ? <TransferReports _id={report._id} /> : null}</>}

                      {user.permissions?.manageReports === true || user.permissions?.administrator === true ? <EditReport reportData={report} /> : null}
                    </div>
                  )}

                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSocketEvents(ReportDetails, [
  'delayReport',
  'finishReport',
  'deleteReport',
  'updateReport',
  'resumeReport',
  'reopenReport',
  'receiveReport',
  'transferredReport',
]);
