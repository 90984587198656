import React from 'react'
import { useTranslation } from 'react-i18next'
const Category = () => {
  const { t } = useTranslation('Category')
  const categoriesArray = [
    { label: t('all'), value: '' },
    { label: t('electricity'), value: 'electricity' },
    { label: t('plumbing'), value: 'plumbing' },
    { label: t('carpentry'), value: 'carpentry' },
    { label: t('ac'), value: 'air-conditioning' },
    { label: t('communication'), value: 'communication' },
    { label: t('elevator'), value: 'elevator' },
    { label: t('painting'), value: 'painting' },
    { label: t('others'), value: 'others' },
  ]

  return categoriesArray
}

export default Category
