import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import ReportContextProvider from './Context/report.context.js'
import './i18n.js'
import { NextUIProvider } from '@nextui-org/react'
import { ThemeProvider as NextThemesProvider } from 'next-themes'
import { AuthProvider } from './Context/auth.context.js'  // Import the AuthProvider

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AuthProvider> 
      <NextUIProvider>
        <NextThemesProvider attribute="class" defaultTheme="dark">
          <React.Suspense>
            <ReportContextProvider>
              <App />
            </ReportContextProvider>
          </React.Suspense>
        </NextThemesProvider>
      </NextUIProvider>
    </AuthProvider>

  </React.StrictMode>,
)
