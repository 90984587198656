import { Avatar, Select, SelectItem } from '@nextui-org/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import Chart from 'chart.js/auto';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import TranslatePermission from '../TranslatePermissions/TranslatePermissions';

Chart.register(ChartDataLabels);

export default function User(props) {
    const { t } = useTranslation("userPages");
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { userID } = useParams();

    const [user, setUser] = useState(null);
    const [error, setError] = useState('');

    const handlePeriodChange = (event) => {
        queryParams.set('period', event.target.value);
        navigate({ search: queryParams.toString() });
    };

    const getUserReport = async () => {
        setError('');
        const period = queryParams.get('period');
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}reports/getUserReportData/${userID || props.userID}?range=${period || "all"}`, {
                headers: {
                    token: Cookies.get('loginToken'),
                },
            });
            setUser(response.data.data);
            console.log(response.data.data);
        } catch (err) {
            setError(err.response?.data?.err || 'An error occurred');
        }
    };

    useEffect(() => {
        getUserReport();
    }, [queryParams.get('period')]);

    const data = {
        labels: [t('finishedOnTime'), t('finishedLate')],
        datasets: [
            {
                label: t('reports'),
                backgroundColor: ['#66BB6A', '#FF6384'],
                data: [user?.reportData.finishedOnTime || 0, user?.reportData.finishedLate || 0],
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            datalabels: {
                display: true,
                color: 'white',
                textAlign: 'center',
                font: {
                    weight: 'bold',
                    size: 14,
                },
                formatter: (value) => value,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
    };

    return (
        <div className="w-full h-full flex flex-col md:flex-row">
            {/* Sidebar section */}
            <div className="w-full md:w-1/3 text-black dark:text-white flex flex-col items- p-8">
                <Avatar
                    src="https://i.pinimg.com/736x/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg"
                    size="lg"
                    className="mb-4"
                    alt="Profile"
                />
                <h2 className="text-xl font-bold mb-2">{t("user")} : <span className='text-main'>{user?.username || 'Username'}</span></h2>
                <h2 className="text-xl font-bold mb-2">{t("role")} : <span className='text-main'>{user?.role || 'Role'}</span></h2>
                <div className="w-full flex  flex-wrap">
                    <h2 className="font-bold text-xl">{t("permissions")}:</h2>
                    {user?.permissions?.administrator === true ? (
                        <div className="flex items-center mb-2">
                            <span className="bg-main text-black dark:text-white px-2 py-1 rounded-full text-sm">{t("administrator")}</span>
                        </div>
                    ) : (
                        user?.permissions ? (
                            Object.entries(user.permissions)
                                .filter(([key, value]) => value === true) // Filter to only include permissions that are true
                                .map(([per], index) => (
                                    <div className="w-full flex items-center  text-main mb-2" key={index}>
                                        <TranslatePermission  permission={per} />
                                    </div>
                                ))
                        ) : (
                            <div className="text-gray-400">No permissions assigned</div>
                        )
                    )}

                </div>
                <Select
                    name='period'
                    label={t('period')}
                    className="w-3/4 max-md:w-2/3 max-sm:w-5/6 my-4"
                    onChange={handlePeriodChange}
                    value={queryParams.get('period') || ''}
                    radius="sm"
                    size='sm'
                >
                    <SelectItem key="daily" value="daily">{t("daily")}</SelectItem>
                    <SelectItem key="weekly" value="weekly">{t("weekly")}</SelectItem>
                    <SelectItem key="monthly" value="monthly">{t("monthly")}</SelectItem>
                    <SelectItem key="all" value="all">{t("all")}</SelectItem>
                </Select>
                {/* Placeholder for additional user data */}
                <div className="w-3/4 max-md:w-2/3 max-sm:w-5/6">
                    <h2>{t("totalReports")}: <span className="text-main font-extrabold">{user?.reportData.totalReports?.toString() || '0'}</span></h2>
                    <h2>{t("finishedReports")}: <span className="text-main font-extrabold">{user?.reportData.finishedReports?.toString() || '0'}</span></h2>
                    <h2>{t("finishedOnTime")}: <span className="text-main font-extrabold">{user?.reportData.finishedOnTime?.toString() || '0'}</span></h2>
                    <h2>{t("finishedLate")}: <span className="text-main font-extrabold">{user?.reportData.finishedLate?.toString() || '0'}</span></h2>
                    <h2>{t("finishReportAvg")}: <span className="text-main font-extrabold">{user?.reportData.finishReportAvg ? Math.round(user.reportData.finishReportAvg).toString() : '0'}</span> {t("minute")}</h2>
                </div>
            </div>
            {/* Content section */}
            <div className="w-full md:w-2/3 p-8">
                <h2 className="w-full text-center text-2xl font-bold mb-4">{t("userPerformance")}</h2>
                <div className="w-full flex items-center justify-center my-3">
                    <div className="w-full max-w-4xl h-80">
                        {error ? <h2 className="text-center">{error}</h2> : <Pie options={options} data={data} />}
                    </div>
                </div>
            </div>
        </div>
    );
}
