import { useEffect, useState } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import {
  Button,
  Textarea,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@nextui-org/react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { FaCheck } from 'react-icons/fa6'
import ButtonLoader from '../ButtonLoader/ButtonLoader'

const FinishReport = (props) => {
  const { t } = useTranslation('ReportActions')
  const { i18n } = useTranslation()

  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const [reportId, setReportId] = useState('')
  const [messageError, setMessageError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setReportId(props._id)
  }, [props._id])

  async function handleFinishReport(values) {
    setIsLoading(true)
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}reports/finishReport/${reportId}`,
        values,
        {
          headers: {
            token: Cookies.get('loginToken'),
          },
        },
      )
      if (response.data.message === 'success') {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      setMessageError(error.response?.data?.err || 'حدث خطأ ما')
    }
  }

  const validation = Yup.object({
    finishedReason: Yup.string()
      .min(1, 'يجب أن يحتوي السبب على حرف واحد على الأقل')
      .max(240, 'يجب ألا يتجاوز السبب 240 حرفًا'),
  })

  const formik = useFormik({
    initialValues: {
      finishedReason: '',
    },
    validationSchema: validation,
    onSubmit: handleFinishReport,
  })

  return (
    <>
      <Button className="bg-finish-color text-white" onPress={onOpen} size="sm">
        <FaCheck />
        {t('finish')}
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="2xl"
         placement='center'
        dir={i18n.dir()}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {t('finishReport')}
              </ModalHeader>
              <form className="my-5" onSubmit={formik.handleSubmit}>
                <ModalBody>
                  <Textarea
                    name="finishedReason"
                    minRows={5}
                    className="w-full"
                    label={t('reason')}
                    onChange={formik.handleChange}
                    value={formik.values.finishedReason}
                  />
                  {messageError ? messageError : null}
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="light" onPress={onClose}>
                    {t('cancel')}
                  </Button>
                  <Button
                    type="submit"
                    className={
                      isLoading
                        ? 'bg-transparent'
                        : 'bg-finish-color text-white'
                    }
                  >
                    {isLoading ? <ButtonLoader /> : t('submit')}
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}

export default FinishReport
