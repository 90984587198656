import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import QRProtectedRoute from './Components/QRProtectedRoute/QRProtectedRoute.jsx'
import Layout from './Components/Layout/Layout.jsx'
import { Toaster } from 'react-hot-toast'
import Permissioned from './Components/PagePermission/PagePermission.jsx'
import ReportDetails from './Components/ReportDetails/ReportDetails.jsx'
import OngoingReportsFragment from './Components/OngoingReportsFragment/OngoingReportsFragment.jsx'
import UserProfile from './Components/UserProfile/UserProfile.jsx'
import UsersFragment from './Components/UsersFragment/UsersFragment.jsx'
import MyProfile from './Components/MyProfile/MyProfile.jsx'
import IsAuth from './Components/IsAuth/IsAuth.jsx'

const Home = React.lazy(() => import('./Components/Home/Home.jsx'))
const NotFound = React.lazy(() => import('./Components/NotFound/NotFound.jsx'))
const Login = React.lazy(() => import('./Components/Login/Login.jsx'))
const QrCheck = React.lazy(() => import('./Components/QrCheck/QrCheck.jsx'))
const Dashboard = React.lazy(() => import('./Components/Dashboard/Dashboard.jsx'))
const AllUsers = React.lazy(() => import('./Components/AllUsers/AllUsers.jsx'))
const OngoingReports = React.lazy(() => import('./Components/OngoingReports/OngoingReports.jsx'))
const AllReports = React.lazy(() => import('./Components/AllReports/AllReports.jsx'))
const DashboardData = React.lazy(() => import('./Components/DashboardData/DashboardData.jsx'))
const AddQr = React.lazy(() => import('./Components/AddQr/AddQr.jsx'))
const AddOneQr = React.lazy(() => import('./Components/AddOneQr/AddOneQr.jsx'))
const AddManyQr = React.lazy(() => import('./Components/AddManyQr/AddManyQr.jsx'))
const RevokeQr = React.lazy(() => import('./Components/RevokeQr/RevokeQr.jsx'))
const AddUser = React.lazy(() => import('./Components/AddUser/AddUser.jsx'))
const AddEmployeeQr = React.lazy(() => import('./Components/AddEmployeeQr/AddEmployeeQr.jsx'))
const AddGuestReport = React.lazy(() => import('./Components/AddGuestReport/AddGuestReport.jsx'))
const AddEmployeeReport = React.lazy(() => import('./Components/AddEmployeeReport/AddEmployeeReport.jsx'))
const Report = React.lazy(() => import('./Components/Report/Report.jsx'))
const AllReportsFragment = React.lazy(() => import('./Components/AllReportsFragment/AllReportsFragment.jsx'))
const ActivityLogs = React.lazy(() => import('./Components/ActivityLogs/ActivityLogs.jsx'))
const MyReports = React.lazy(() => import('./Components/MyReports/MyReports.jsx'))

function App() {
  let routers = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        { index: true, element: <Home /> },
        { path: 'login', element: <Login /> },
        { path: 'MyProfile', element: <IsAuth><MyProfile /></IsAuth> },
        { path: 'qrCheck/:urlToken', element: <QrCheck /> },
        {
          path: 'addguestreport',
          element: (
            <QRProtectedRoute>
              <AddGuestReport />
            </QRProtectedRoute>
          ),
        },
        {
          path: 'addemployeereport',
          element: (
            <QRProtectedRoute>
              <AddEmployeeReport />
            </QRProtectedRoute>
          ),
        },
        {
          path: 'dashboard',
          element: <Dashboard />,
          children: [
            { index: true, element: <Permissioned permission='viewCharts'><DashboardData /></Permissioned> },
            {
              path: 'users', element: <Permissioned permission='manageUsers'> <UsersFragment /></Permissioned>, children: [
                { index: true, element: <Permissioned permission='manageUsers'><AllUsers /></Permissioned> },
                { path: 'adduser', element: <Permissioned permission='manageUsers'><AddUser /> </Permissioned> },
                { path: ":userID", element: <Permissioned permission='manageUsers'><UserProfile /> </Permissioned> }
              ]
            },
            // { path: 'usersPerformance', element: <Permissioned permission='getUserDetails'><AllUsers /> </Permissioned> },

            {
              path: 'report',
              element: <Report />,
              children: [
                { index: true, element: <NotFound /> },
                {
                  path: 'AllReports',
                  element: <Permissioned permission='viewAllReports'><AllReportsFragment /></Permissioned>,
                  children: [
                    { index: true, element: <Permissioned permission='viewAllReports'><AllReports /></Permissioned> },
                    { path: ':reportId', element: <Permissioned permission='viewAllReports'><ReportDetails /></Permissioned> },
                  ],
                },
                {
                  path: 'OngoingReports',
                  element: <Permissioned permission='viewOngoingReports'><OngoingReportsFragment /></Permissioned>,
                  children: [
                    { index: true, element: <Permissioned permission='viewOngoingReports'><OngoingReports /></Permissioned> },
                    { path: ':reportId', element: <Permissioned permission='viewOngoingReports'><ReportDetails /></Permissioned> },
                  ],
                },
              ],
            },
            { path: 'ActivityLogs', element: <ActivityLogs></ActivityLogs> },
            {
              path: 'qr',
              element: <Permissioned permission='manageQR' ><AddQr /></Permissioned>,
              children: [
                { path: 'addone', element: <Permissioned permission='manageQR'><AddOneQr /> </Permissioned> },
                { path: 'addemployeeqr', element: <Permissioned permission='manageQR'><AddEmployeeQr /> </Permissioned> },
                { path: 'addmany', element: <Permissioned permission='manageQR'><AddManyQr /> </Permissioned> },
                { path: 'revoke', element: <Permissioned permission='manageQR'><RevokeQr /></Permissioned> },
              ],
            },
          ],
        },
        { path: 'myReports', element: <Permissioned permission='receiveReports'><MyReports /> </Permissioned> },
        { path: 'myReports/:reportId', element: <Permissioned permission='receiveReports'><ReportDetails /> </Permissioned> },
        { path: '*', element: <NotFound /> },
      ],
    },
  ])

  return (
    <>
      <RouterProvider router={routers}></RouterProvider>
      <Toaster />
    </>
  )
}

export default App
