import React from 'react';
import { useTranslation } from 'react-i18next';

const CalculateFinishedTime = ({ duration }) => {
  const { t } = useTranslation('Time');

  const formatTimeDifference = (minutes) => {
    if (minutes <= 0) return '0' + t('m'); // Handle zero or negative duration

    const days = Math.floor(minutes / 1440);
    const hours = Math.floor((minutes % 1440) / 60);
    const remainingMinutes = Math.floor(minutes % 60);

    return [
      days > 0 && `${days}${t('d')}`,
      (hours > 0 || days > 0) && `${hours}${t('h')}`,
      `${remainingMinutes}${t('m')}`,
    ]
      .filter(Boolean)
      .join(' ');
  };

  return <div>{formatTimeDifference(duration)}</div>;
};

export default CalculateFinishedTime;
