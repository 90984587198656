import { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
  Button,
  Textarea,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@nextui-org/react';
import { MdOutlinePause } from 'react-icons/md';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import ButtonLoader from '../ButtonLoader/ButtonLoader';
import UnDelayReport from '../UnDelayReport/UnDelayReport';

const DelayReport = (props) => {
  const { t } = useTranslation('ReportActions');
  const { i18n } = useTranslation();

  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [reportId, setReportId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [resumed, setResumed] = useState(true);

  useEffect(() => {
    setReportId(props._id);
  }, [props._id]);

  const validationSchema = Yup.object({
    delayedReason: Yup.string()
      .min(1, 'يجب أن يكون الوصف مكونًا من حرف واحد على الأقل')
      .max(240, 'يجب ألا يتجاوز الوصف 240 حرفًا'),
  });

  const formik = useFormik({
    initialValues: {
      delayedReason: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleDelayReport,
  });

  async function handleDelayReport(values) {
    setIsLoading(true);
    try {
      const delayReport = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}reports/delayReport/${reportId}`,
        values,
        {
          headers: {
            token: Cookies.get('loginToken'),
          },
        }
      );
      if (delayReport.data.message === 'success') {
        setResumed(false);
        onOpenChange();
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setMessageError(err.response.data.err);
    }
  }

  return (
    <>
      {resumed ? (
        <Button
          color='danger'
          className='bg-delay-color text-white'
          onPress={onOpen}
          size='sm'
        >
          <MdOutlinePause />
          {t('delay')}
        </Button>
      ) : (
        <UnDelayReport reportId={reportId}></UnDelayReport>
      )}

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size='2xl'
        placement='center'
        dir={i18n.dir()}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                {' '}
                {t('delayReport')}
              </ModalHeader>
              <form className='my-5' onSubmit={formik.handleSubmit}>
                <ModalBody>
                  <Textarea
                    isRequired
                    errorMessage={t('delayRequird')}
                    name='delayedReason'
                    minRows={5}
                    className='w-full'
                    label={t('reason')}
                    onChange={formik.handleChange}
                    value={formik.values.delayedReason}
                  />
                  {messageError ? messageError : null}
                </ModalBody>
                <ModalFooter>
                  <Button color='danger' variant='light' onPress={onClose}>
                    {t('cancel')}
                  </Button>

                  <Button
                    type='submit'
                    className={
                      isLoading ? 'bg-transparent' : 'bg-delay-color text-white'
                    }
                  >
                    {isLoading ? <ButtonLoader /> : t('submit')}
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default DelayReport;
