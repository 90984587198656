import { Link } from 'react-router-dom'
import Logo from '../../assets/footer logo.png'
import { useTranslation } from 'react-i18next'

const MyFooter = () => {
  const { t } = useTranslation('Footer')

  return (
    <footer className="bg-white dark:bg-black">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-6 sm:py-4 mobile:py-2">
        <div className="flex items-center justify-between">
          <Link
            to="https://mern.agency/"
            className="flex items-center mb-4 mobile:mb-2 space-x-3 rtl:space-x-reverse"
          >
            <img src={Logo} className="h-8 rounded-full" alt="mern Agency" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white mobile:text-lg">
              {t('mern')}
            </span>
          </Link>
          <ul className="flex flex-wrap items-center mb-4 mobile:mb-2 text-sm font-medium text-gray-500 dark:text-gray-400">
            <li>
              <Link
                to="https://mern.agency/about"
                className="hover:underline me-4 md:me-6 mobile:text-xs mobile:me-2"
              >
                {t('about')}
              </Link>
            </li>
            <li>
              <Link
                to="https://mern.agency/contact"
                className="hover:underline mobile:text-xs mobile:me-2"
              >
                {t('contact')}
              </Link>
            </li>
          </ul>
        </div>
        <hr className="my-6 border-gray-200 mx-auto dark:border-gray-700 lg:my-8 md:my-6 sm:my-4 mobile:my-2" />
        <span className="block text-sm text-gray-500 text-center dark:text-gray-400">
          © 2024{' '}
          <Link
            to="https://mern.agency/"
            className="hover:underline text-red-600"
          >
            {t('mern')}
          </Link>
          {t('copyRights')}
        </span>
      </div>
    </footer>
  )
}

export default MyFooter
