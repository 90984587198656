import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Select, SelectItem, Avatar } from '@nextui-org/react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { BiTransfer } from "react-icons/bi";
import ButtonLoader from '../ButtonLoader/ButtonLoader'

const TransferReport = ({ _id }) => {
    const { t } = useTranslation('ReportActions')
    const { i18n } = useTranslation()

    const { isOpen, onOpen, onOpenChange } = useDisclosure()
    const [messageError, setMessageError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [users, setUsers] = useState([])

    async function GetUsers() {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}users/?permissions=receiveReports`, {
                headers: {
                    token: Cookies.get('loginToken')
                }
            })
            console.log(res.data.users)
            setUsers(res.data.users)
        } catch (err) {
            console.log(err)
        }
    }

    async function handleTransferReport(values) {
        console.log(values)
        setIsLoading(true)
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_BASE_URL}reports/transferReport/${_id}`,
                values,
                {
                    headers: {
                        token: Cookies.get('loginToken'),
                    },
                },
            )
            if (response.data.message === 'success') {
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false)
            setMessageError(error.response?.data?.err || 'حدث خطأ ما')
        }
    }

    const validation = Yup.object({
        receiverId: Yup.string().required(t('required'))
    })

    const formik = useFormik({
        initialValues: {
            receiverId: '',
        },
        validationSchema: validation,
        onSubmit: handleTransferReport,
    })

    return (
        <>
            <Button className="text-white" color='success' onPress={() => { onOpen(); GetUsers(); }} size="sm">
                <BiTransfer />
                {t('transferReport')}
            </Button>
            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                size="2xl"
                dir={i18n.dir()}
                placement='center'
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">
                                {t('transferReport')}
                            </ModalHeader>
                            <form className="my-5" onSubmit={formik.handleSubmit}>
                                <ModalBody>
                                    <Select
                                        name="receiverId"
                                        items={users}
                                        label={t("transferto")}
                                        placeholder={t("selectUser")}
                                        labelPlacement="outside"
                                        className="max-w-xs"
                                        isRequired
                                        isInvalid={formik.errors.receiverId && formik.touched.receiverId}
                                        errorMessage={formik.errors.receiverId}
                                        onBlur={formik.handleBlur}
                                        onChange={(e) => formik.setFieldValue('receiverId', e.target.value)}
                                        value={formik.values.receiverId}
                                    >
                                        {users.map((user) => (
                                            <SelectItem key={user._id} value={user._id} textValue={user.username}>
                                                <div className="flex gap-2 items-center">
                                                    <Avatar className="flex-shrink-0" size="sm" />
                                                    <div className="flex flex-col">
                                                        <span className="text-small">{user.username}</span>
                                                    </div>
                                                </div>
                                            </SelectItem>
                                        ))}
                                    </Select>
                                    {messageError ? <div className="text-red-500">{messageError}</div> : null}
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="danger" variant="light" onPress={onClose}>
                                        {t('cancel')}
                                    </Button>
                                    <Button
                                        type="submit"
                                        className={isLoading ? 'bg-transparent' : 'text-white'}
                                        color='success'
                                    >
                                        {isLoading ? <ButtonLoader /> : t('submit')}
                                    </Button>
                                </ModalFooter>
                            </form>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}

export default TransferReport
