import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Button, DropdownItem, DropdownTrigger, Dropdown, DropdownMenu, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Avatar, Divider, } from '@nextui-org/react'

//loacl imports
import LanguageSelector from '../LanguageSelector/LanguageSelector.jsx'
import { ThemeSwitcher } from '../ThemeSwitcher/ThemeSwitcher.jsx'
import Logo from '../../assets/LOGO.png'
import { ChevronDown, Chart, AllReports, Ongoing, Users, AddUser, AddQr, ActivityLogs, } from '../Icons/Icons.jsx'
import { useAuth } from '../../Context/auth.context';

//icons
import { CiUser } from "react-icons/ci";
import { BiBriefcase } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { TbReportSearch } from "react-icons/tb";

const MainNav = () => {

  const { t } = useTranslation('MainNav')// translation
  const { i18n } = useTranslation()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  //authentication
  const { user, logout } = useAuth()
  console.log(user);
  const menuItems = [
    {
      label: t('MainNavSettingsList.reportChart'),
      path: '/dashboard',
      icon: <Chart className="text-primary" fill="currentColor" size={30} />,
      permission: "viewCharts"
    },
    {
      label: t('MainNavSettingsList.addReport'),
      path: '/qrcheck/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiZW1wbG95ZWUiLCJpYXQiOjE3MTM5ODgxODR9.yAuh6ssF0QmEl2NERNtpdTnETffKR4nHJJycKypITr8',
      icon: <Chart className="text-primary" fill="currentColor" size={30} />,
      permission: ""
    },
    {
      label: t('MainNavSettingsList.allReports'),
      path: '/dashboard/report/AllReports',
      icon: <AllReports className="text-primary" fill="currentColor" size={30} />,
      permission: "viewAllReports"
      ,
    },
    {
      label: t('MainNavSettingsList.onGoingReports'),
      path: '/dashboard/report/OngoingReports',
      icon: <Ongoing className="text-primary" fill="currentColor" size={30} />,
      permission: "viewOngoingReports"
    },
    {
      label: t('MainNavSettingsList.users'),
      path: '/dashboard/users',
      icon: <Users className="text-primary" fill="currentColor" size={30} />,
      permission: "manageUsers"
    },
    {
      label: t('MainNavSettingsList.addUser'),
      path: '/dashboard/users/adduser',
      icon: <AddUser className="text-primary" fill="currentColor" size={30} />,
      permission: "manageUsers"
    },
    {
      label: t('MainNavSettingsList.addQr'),
      path: '/dashboard/qr/addone',
      icon: <AddQr className="text-primary" fill="currentColor" size={30} />,
      permission: "manageQR"
    },
    {
      label: t('MainNavSettingsList.employeeQr'),
      path: '/dashboard/qr/addemployeeqr',
      icon: <AddQr className="text-primary" fill="currentColor" size={30} />,
      permission: "manageQR"
    },
    {
      label: t('MainNavSettingsList.AddManyQr'),
      path: '/dashboard/qr/addmany',
      icon: <AddQr className="text-primary" fill="currentColor" size={30} />,
      permission: "manageQR"
    },
    {
      label: t('MainNavSettingsList.RevokeQr'),
      path: '/dashboard/qr/revoke',
      icon: <AddQr className="text-primary" fill="currentColor" size={30} />,
      permission: "manageQR"
    },
    {
      label: t('MainNavSettingsList.activityLogs'), // Adjust label as per your translation file
      path: '/dashboard/activitylogs', // Adjust path based on your route setup
      icon: <ActivityLogs className="text-primary" fill="currentColor" size={30} />,
      permission: "viewAuditLogs"
    },
    {
      label: t('MainNavSettingsList.userPerformance'), // Adjust label as per your translation file
      path: '/dashboard/users/performance', // Adjust path based on your route setup
      icon: <ActivityLogs className="text-primary" fill="currentColor" size={30} />,
      permission: "getUserDetails"
    },
  ]

  // icons
  const icons = {
    chevron: <ChevronDown fill="currentColor" size={16} />,
  }

  function ReturnMenuItems(item, index) {
    if (user?.permissions?.[item.permission] === true || item.permission === "" || user?.permissions?.administrator === true) {
      return (
        <DropdownItem
          key={index}
          as={Link}
          to={item.path}
          startContent={item.icon}
        >
          {item.label}
        </DropdownItem>
      );
    }
    return null;
  }

  function ReturnToggleMenuItems(item, index) {
    
    if (user?.permissions?.[item.permission] === true || item.permission === "" || user?.permissions?.administrator === true) {
      return (
        <div key={`${item}-${index}`}>
          <NavbarMenuItem onClick={() => setIsMenuOpen(false)}>
            <Link
              color="foreground"
              className="w-full"
              to={item.path}
              size="lg"
            >
              {item.label}
            </Link>
          </NavbarMenuItem>
        </div>
      );
    }
    return null;
  }


  return (
    <>
      <Navbar
        onMenuOpenChange={setIsMenuOpen}
        isMenuOpen={isMenuOpen}
        maxWidth="full"
      >
        <NavbarContent>
          <NavbarMenuToggle
            aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
            className="sm:hidden"
          />
          <NavbarBrand>
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
          </NavbarBrand>
        </NavbarContent>

        {user ? (
          <NavbarContent className="hidden sm:flex gap-4" justify="center">
            <Dropdown>
              <NavbarItem>
                <DropdownTrigger>
                  <Button
                    disableRipple
                    className="p-0 bg-transparent data-[hover=true]:bg-transparent"
                    endContent={icons.chevron}
                    radius="sm"
                    variant="light"
                  >
                    {t('MainNavSettings')}
                  </Button>
                </DropdownTrigger>
              </NavbarItem>

              <DropdownMenu
                aria-label="ACME features"
                className="w-[340px]"
                itemClasses={{
                  base: 'gap-4',
                }}
              >
                {menuItems.map((item, index) =>
                  ReturnMenuItems(item, index, item.permission)
                )}
              </DropdownMenu>

            </Dropdown>
          </NavbarContent>
        ) : null}

        <NavbarContent justify="end">
          <NavbarItem>
            <ThemeSwitcher />
          </NavbarItem>

          <NavbarItem>
            <LanguageSelector />
          </NavbarItem>

          {user ?
            <Dropdown placement="bottom-end" dir={i18n.dir()}>
              <DropdownTrigger>
                <Avatar
                  isBordered
                  as="button"
                  className="transition-transform"
                  color="secondary"
                  name="Jason Hughes"
                  size="sm"
                  src="https://i.pinimg.com/736x/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg"
                />
              </DropdownTrigger>
              <DropdownMenu aria-label="Profile Actions" variant="flat">

                <DropdownItem key="user" dir={i18n.dir()}><h2 className='flex items-center'> <CiUser className='mx-1 text-lg' /> {t("user")} : <span className='text-main mx-1'>{user.username}</span></h2></DropdownItem>
                <DropdownItem key="role" dir={i18n.dir()}><h2 className='flex items-center'> <BiBriefcase className='mx-1 text-lg' /> {t("role")} : <span className='text-main mx-1'>{user.role}</span></h2></DropdownItem>
                <DropdownItem><Divider></Divider></DropdownItem>
                <DropdownItem key="profile" dir={i18n.dir()}><Link className='flex items-center' to="/MyProfile"><CgProfile className='mx-1 text-lg' />{t("myProfile")}</Link></DropdownItem>
                {user?.permissions?.receiveReports === true || user?.permissions?.administrator ? <DropdownItem key="myReports" dir={i18n.dir()}><Link className='flex items b-center' to="/myReports"><TbReportSearch className='mx-1 text-lg' />{t("myReports")}</Link></DropdownItem> : null}
                <DropdownItem key="logout">
                  <Button className='w-full rounded' radius='none' color='danger' onClick={() => { logout() }}>{t("MainNavLogout")}</Button>
                </DropdownItem>

              </DropdownMenu>
            </Dropdown>
            :

            <NavbarItem>
              <Link to="/login">
                <Button className="bg-main text-white" variant="flat" size="sm">
                  {t('MainNavLogin')}
                </Button>
              </Link>
            </NavbarItem>
          }
        </NavbarContent>

        <NavbarMenu className="flex justify-center items-center">
          {user &&
            menuItems.map((item, index) =>
              ReturnToggleMenuItems(item, index)
            )}
        </NavbarMenu>
      </Navbar>
    </>
  )
}

export default MainNav
