import React from 'react'
import User from '../User/User'
import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'
export default function MyProfile() {
  const loginToken = Cookies.get('loginToken')
  let userId = jwtDecode(loginToken).id
  console.log(userId);
  return (<User userID={userId} />)
}
