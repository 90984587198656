import React, { useState } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import {
    Button,
    Textarea,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
} from '@nextui-org/react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { GoIssueReopened } from "react-icons/go";
import ButtonLoader from '../ButtonLoader/ButtonLoader'

const ReopenReport = ({ _id }) => {
    const { t } = useTranslation('ReportActions')
    const { i18n } = useTranslation()
    const { isOpen, onOpen, onOpenChange } = useDisclosure()
    const [messageError, setMessageError] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const formik = useFormik({
        initialValues: {
            reopenReason: ''
        },
        validationSchema: Yup.object({
            reopenReason: Yup.string().required(t('reasonRequired'))
        }),
        onSubmit: async (values) => {
            setIsLoading(true)
            setMessageError('')
            try {
                const response = await axios.patch(
                    `${process.env.REACT_APP_BASE_URL}reports/reopenReport/${_id}`,
                    { reopenReason: values.reopenReason },
                    {
                        headers: {
                            token: Cookies.get('loginToken'),
                        },
                    },
                )
                if (response.data.message === 'sucsess') {
                    setIsLoading(false)
                    onOpenChange(false) // Close the modal
                }
            } catch (error) {
                setIsLoading(false)
                setMessageError(error.response?.data?.err || t('unknownError'))
            }
        }
    })

    return (
        <>
            <Button className="text-white" color='primary' onPress={onOpen} size="sm">
                <GoIssueReopened />
                {t('reopen')}
            </Button>
            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                size="2xl"
                dir={i18n.dir()}
                placement='center'
            >
                <ModalContent>
                    {(onClose) => (
                        <form onSubmit={formik.handleSubmit}>
                            <ModalHeader className="flex flex-col gap-1">
                                {t('reopenReport')}
                            </ModalHeader>
                            <ModalBody>
                                <Textarea
                                placeholder={t("reason")}
                                    name="reopenReason"
                                    value={formik.values.reopenReason}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                                {formik.touched.reopenReason && formik.errors.reopenReason ? (
                                    <p className="text-red-500 mt-2">{formik.errors.reopenReason}</p>
                                ) : null}
                                {messageError && <p className="text-red-500 mt-2">{messageError}</p>}
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    {t('cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    className={isLoading ? 'bg-transparent' : 'text-white'}
                                    color='primary'
                                >
                                    {isLoading ? <ButtonLoader /> : t('submit')}
                                </Button>
                            </ModalFooter>
                        </form>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}

export default ReopenReport
