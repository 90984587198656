import React from 'react';
import { useAuth } from '../../Context/auth.context';
import NotFound from '../NotFound/NotFound';
import Loader from '../Loader/Loader';

const Permissioned = ({ children, permission }) => {
    const { user, loading, isAuthenticated } = useAuth();

    if (loading) {
        return <Loader /> 
    }

    if (!isAuthenticated()) {
        return <NotFound />;
    }

    if (user?.permissions?.administrator || user?.permissions?.[permission]) {
        return children;
    }

    return <NotFound />;
};

export default Permissioned;
