import { ThreeDots } from 'react-loader-spinner'
const Loader = () => {
  return (
    <>
      <div className="w-full h-full fixed top-0 start-0 bg-gray-300 dark:bg-black flex justify-center items-center z-50">
        <ThreeDots
          visible={true}
          width="50"
          color="#cfae78"
          ariaLabel="infinity-spin-loading"
        />
      </div>
    </>
  )
}

export default Loader
