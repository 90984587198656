import { useContext, useEffect, useState } from 'react';
import { reportContext } from '../Context/report.context.js';

const withSocketEvents = (WrappedComponent, events) => {
  return (props) => {
    const context = useContext(reportContext);
    const [tableReports, setTableReports] = useState([]);

    const handleReportChange = (reportData) => {
      console.log('Handling report change:', reportData);
      if (reportData && reportData._id) {
        setTableReports((prevReports) => {
          const index = prevReports.findIndex(report => report._id === reportData._id);
          if (index !== -1) {
            const updatedReports = [...prevReports];
            updatedReports[index] = reportData;
            return updatedReports;
          } else {
            return [...prevReports, reportData];
          }
        });
      }
    };

    useEffect(() => {
      const eventHandlers = events.reduce((handlers, event) => {
        handlers[event] = (eventData) => {
          console.log(`Received event: ${event}`, eventData);
          if (event === 'newReport') {
            if (eventData && eventData._id) {
              setTableReports((prevReports) => [eventData, ...prevReports]);
            }
          } else if (event === 'deleteReport') {
            if (eventData && eventData._id) {
              setTableReports((prevReports) => prevReports.filter(report => report._id !== eventData._id));
            }
          } else {
            handleReportChange(eventData);
          }
        };
        return handlers;
      }, {});

      // Attach event handlers
      Object.keys(eventHandlers).forEach(event => {
        console.log(`Attaching handler for event: ${event}`);
        context.socket.on(event, eventHandlers[event]);
      });

      // Cleanup event handlers on unmount
      return () => {
        Object.keys(eventHandlers).forEach(event => {
          console.log(`Removing handler for event: ${event}`);
          context.socket.off(event, eventHandlers[event]);
        });
      };
    }, [context, events]);

    return <WrappedComponent {...props} tableReports={tableReports} setTableReports={setTableReports} />;
  };
};

export default withSocketEvents;
