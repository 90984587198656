import { ThreeDots } from 'react-loader-spinner'
const ButtonLoader = () => {
  return (
    <>
      <ThreeDots height={20} width={40} color="#F1C590" />
    </>
  )
}

export default ButtonLoader
