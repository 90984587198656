import React, { useState } from 'react'
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import axios from 'axios';
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next';
import ButtonLoader from '../ButtonLoader/ButtonLoader';
import { MdCallReceived } from "react-icons/md";
export default function ReciveReport({ _id }) {
    const { t } = useTranslation("ReciveReport")
    const { i18n } = useTranslation()

    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [messageError, setMessageError] = useState(null);
    const [isLoading, setIsLoading] = useState(false)

    async function ReciveReport() {
        setMessageError(true)
        setMessageError(null)
        setIsLoading(true)
        const res = await axios.patch(`${process.env.REACT_APP_BASE_URL}reports/receiveReport/${_id}`, {},
            {
                headers: {
                    token: Cookies.get('loginToken'),
                },
            },
        ).then((res) => {
            setMessageError(false)
            onOpenChange()
            setIsLoading(false)
        }).catch((err) => {
            setMessageError(false)
            setMessageError(err.response.data.err);
            setIsLoading(false)
        })
    }

    return <>
        <Button onPress={onOpen} size='sm' radius='sm' className='text-white bg-emerald-600'>
        <MdCallReceived />

            {t("reciveReport")}
            </Button>
        <Modal isOpen={isOpen} onOpenChange={onOpenChange} isKeyboardDismissDisabled={true} dir={i18n.dir()}>
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">{t("reciveReport")}</ModalHeader>
                        <ModalBody>
                            <h2>{t("warning")}</h2>
                            {messageError ? <h2 className='text-red-500'>{messageError}</h2> : null}

                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onClose}>
                                {t("no")}
                            </Button>
                            <Button className='bg-emerald-600 text-white' onClick={ReciveReport}>
                                {isLoading ? <ButtonLoader /> : t("yes")}
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    </>
}
