import React, { useEffect } from 'react'
import NotFound from '../NotFound/NotFound'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const QRProtectedRoute = ({ children }) => {
  // const Navigate = useNavigate();
  const token = Cookies.get('qrToken')

  // useEffect(() => {
  //   if (!token) {
  //     Navigate('/login');
  //     return;
  //   }

  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}qr/qrCheck/${token}`)
  //     .then(({ data }) => {
  //       if (data.message !== 'success') {
  //         Cookies.remove('qrToken');
  //         Navigate('/login');
  //       }
  //     })
  //     .catch((err) => {
  //       Cookies.remove('qrToken');
  //       Navigate('/login');
  //       console.error('Error:', err);
  //     });
  // }, [token]);

  if (!token) {
    return <NotFound />
  } else {
    return children
  }
}

export default QRProtectedRoute
